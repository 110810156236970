




































































































































































import { Vue, Prop, Component, Inject } from "vue-property-decorator";
import { Profile } from "@/models/profile/Profile";
import { ItemWrapGrid, ContextMenu } from "ui-gallery";
import Axios from "axios";
import { SuccessResponse } from "../../../models/api/Response";
import { ITemplate, ITemplateContent } from "@/models/profile";
import { config } from "@/config";
import { copyToClipBoard } from "../../../models/utilities/clip-board";

@Component({ components: { ItemWrapGrid, ContextMenu } })
export default class extends Vue {
    @Prop({ default: [] })
    readonly profiles!: Profile[];

    @Prop({ default: false })
    readonly isGroup!: boolean;

    @Inject()
    private notify!: (message: string, timeout?: number, color?: string) => Promise<void>;

    private contextMenuItem?: Profile = undefined;

    private invokeProfile(profile: Profile) {
        this.$emit("profileSelected", profile);
    }

    private add() {
        this.$emit("createNew");
    }

    private getTemplateContent(template?: ITemplate<ITemplateContent>): ITemplateContent | {} {
        if (template) {
            return template.content;
        }
        return {};
    }

    private config = config;

    // #region context menu commands
    private edit() {
        if (!this.contextMenuItem) return;
        this.$emit("edit", this.contextMenuItem);
        this.closeContexMenu();
    }

    private remove() {
        if (!this.contextMenuItem) return;
        this.$emit("remove", this.contextMenuItem);
        this.closeContexMenu();
    }

    private copyToclipBoard() {
        if (!this.contextMenuItem) return;
        const str = config.camelUrl + "/api/profiles/render/" + this.contextMenuItem.key;
        copyToClipBoard(str);
        this.notify("URLをコピーしました");
        this.closeContexMenu();
    }

    private openPublishPage() {
        if (!this.contextMenuItem) return;
        window.open(config.camelUrl + "/api/profiles/render/" + this.contextMenuItem.key);
        this.closeContexMenu();
    }

    private onShowContextMenu(e: MouseEvent, profile: Profile) {
        this.contextMenuItem = profile;
        const menu = this.$refs.menu as ContextMenu | undefined;
        if (menu) {
            menu.show(e.x - 240, e.y - 40);
        }
    }

    private closeContexMenu() {
        this.contextMenuItem = undefined;
        const menu = this.$refs.menu as ContextMenu | undefined;
        if (menu) {
            menu.close();
        }
    }
    // #endregion
}
