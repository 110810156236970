import { interval } from "rxjs";

/**
 * サブウィンドウを開きます．
 * @param url URL
 * @param title タイトル
 * @param Ffeatures オプション
 */
export function openSubWindowAsync(url: string, title: string, features: string): Promise<void> {
    return new Promise(async resolve => {
        const subWindow = window.open(url, title, features);
        if (!subWindow) throw new Error("Windowの接続に失敗しました．");

        const subscription = interval(1000 / 3).subscribe(() => {
            if (subWindow.closed) {
                subscription.unsubscribe();
                resolve();
            }
        });
    });
}
