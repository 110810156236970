































































import { Vue, Component } from "vue-property-decorator";
import Axios from "axios";
import { ConfirmDialog, ConfirmDialogContent } from "ui-gallery";
import ProfileListView from "./ProfileListView.vue";
import { SuccessResponse } from "@/models/api/Response";
import { Profile } from "@/models/profile/Profile";
import { openSubWindowAsync } from "@/models/utilities/window";
import ProfilePreviewDialog from "./ProfilePreviewDialog.vue";
import { animate } from "@/models/utilities/animate";
import { container } from "tsyringe";
import { AuthService } from "@/models/auth/AuthService";
import { config } from "../../../config";
import Qs from "qs";
import { from, merge } from "rxjs";
import { map, filter } from "rxjs/operators";

@Component({ components: { ConfirmDialog, ProfileListView, ProfilePreviewDialog } })
export default class ProfileSettingPage extends Vue {
    private myProfiles: Profile[] = [];
    private groupProfiles: Profile[] = [];
    private authService = container.resolve(AuthService);
    selectedTab = null;

    /**
     * コンポーネントが作成されたきに実行されます．
     */
    private async created(): Promise<void> {
        await this.fetchProfiles();
    }

    private async mounted(): Promise<void> {
        await animate(this.$el as HTMLElement, "opacity", "0", 0);
        this.$forceUpdate();
        await animate(this.$el as HTMLElement, "opacity", "1", 0.5, 0);
    }

    private async createNew(): Promise<void> {
        await openSubWindowAsync(
            `${config.camelFrontUrl}/extern/profile-create?${Qs.stringify({
                token: this.authService.token,
                refreshToken: this.authService.refreshToken,
                groupId: this.authService.currentGroupId,
                memberId: this.authService.memberId,
                memberName: this.authService.memberName
            })}`,
            "Camel",
            "menubar=no,location=no,resizable=yes,scrollbars=yes,status=no,resizable=yes,scrollbars=no,toolbar=no,width=1280,height=740");
        await this.fetchProfiles();
    }

    private async fetchProfiles(): Promise<void> {
        try {
            from(Axios.get<SuccessResponse<Profile[]>>(config.camelUrl + "/api/profiles?groupId=" + this.authService.currentGroupId))
                .pipe(
                    map(x => x.data.data),
                    map(x => x.filter(y => y.createdBy !== this.authService.memberId))
                )
                .subscribe(x => this.groupProfiles = [...x]);
            from(Axios.get<SuccessResponse<Profile[]>>(config.camelUrl + "/api/profiles", {
                params: {
                    groupId: this.authService.currentGroupId,
                    memberId: this.authService.memberId
                }
            }))
                .pipe(
                    map(x => x.data.data)
                )
                .subscribe(x => this.myProfiles = [...x]);
        }
        catch (ex) {
            console.error(ex);
            logger.error(ex);
        }
    }

    private onSave() {
        this.fetchProfiles();
    }

    private async onEdit(profile: Profile): Promise<void> {
        await openSubWindowAsync(
            config.camelFrontUrl + "/extern/profile-edit/" + profile.profileId + `?${Qs.stringify({
                token: this.authService.token,
                refreshToken: this.authService.refreshToken,
                groupId: this.authService.currentGroupId,
                memberId: this.authService.memberId,
                memberName: this.authService.memberName
            })}`, "Camel",
            "menubar=no,location=no,resizable=yes,scrollbars=yes,status=no,resizable=yes,scrollbars=no,toolbar=no,width=1280,height=740");
        await this.fetchProfiles();
    }

    private async onPreview(profile: Profile): Promise<void> {
        const previewDialog = this.$refs.profilePreviewDialog as ProfilePreviewDialog | undefined;
        if (!previewDialog) return;
        await previewDialog.showAsync(profile);
    }

    private async onRemove(profile: Profile): Promise<void> {
        try {
            const confirmDialog = this.$refs.confirmDialog as any | undefined;
            if (confirmDialog) {
                const isRemove = await confirmDialog.showAsync(new ConfirmDialogContent({
                    okText: "削除",
                    cancelText: "キャンセル",
                    title: `${profile.name}を削除しますか？`
                }));
                if (isRemove) {
                    const response = await Axios.delete<number>(config.camelUrl + "/api/profiles?profileId=" + profile.profileId);
                    this.fetchProfiles();
                }
            }
        }
        catch (ex) {
            logger.error(ex);
            console.error("プロフィールの削除に失敗しました。", ex);
        }
    }
}
